// Create a range of the dates between to given dates
import BookingService from './booking_service'
import Helpers from './helpers'
import RenderPriceService from './render_price_service'

const helpers = new Helpers()

const getDates = function (startDate, endDate) {
  const dates = []
  let currentDate = startDate
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  while (currentDate < endDate) {
    dates.push(currentDate)
    currentDate = addDays.call(currentDate, 1)
  }
  return dates
}

function numberWithSeparator (x, separator) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

const sameDay = (d1, d2) => {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
}

class Booking {
  constructor (car_id, included_distance, price_per_day) {
    this.carId = car_id
    this.previousStartDate = null
    this.includedDistance = included_distance
    this.pricePerDay = price_per_day
    this.deliveryOption = null
  }

  calculateNumberOfDays () {
    const arrayOfDays = getDates(this.startDate, this.endDate)
    this.numberOfDays = arrayOfDays.length

    const same_day = sameDay(this.startDate, this.endDate)
    if (same_day) {
      this.numberOfDays = 1
    }

    if (this.endsAt > this.startsAt && !same_day) {
      this.numberOfDays += 1
    }
  }

  updateTotalPrice (price) {
    this.totalPrice = price
    this.newPricePerDay = Math.round(this.totalPrice / this.numberOfDays)
  }

  updateKm (input) {
    // @deprecated
    this.kmInput = input
    const pricePackage = this.includedDistance * this.numberOfDays
    this.extraKm = (this.kmInput - pricePackage)
  }

  updateKmInput (input) {
    this.kmInput = input
  }

  updateDeliveryOption (value) {
    this.deliveryOption = value
  }

  updateDates (start_date, end_date, starts_at, ends_at) {
    this.startsAtStr = starts_at
    this.endsAtStr = ends_at

    const starts_at_split = this.startsAtStr.split(':')
    const ends_at_split = this.endsAtStr.split(':')

    start_date.setHours(parseInt(starts_at_split[0]), parseInt(starts_at_split[1]))
    end_date.setHours(parseInt(ends_at_split[0]), parseInt(ends_at_split[1]))

    this.startDate = start_date
    this.endDate = end_date

    this.startsAt = parseInt(starts_at.replace(/\D+/g, ''))
    this.endsAt = parseInt(ends_at.replace(/\D+/g, ''))

    const startDateChanged = (this.previousStartDate == null || this.startDate.toDateString() !== this.previousStartDate.toDateString())

    if (startDateChanged) {
      this.previousStartDate = this.startDate
    }
    this.calculateNumberOfDays()
    return startDateChanged
  }
}

const morning_only_days = []
const afternoon_only_days = []

const restrictMinDate = function (minAllowedDate, dates_json) {
  const today = flatpickr.formatDate(new Date(Date.now()), 'Y-m-d')
  const minAllowedDateWithoutTime = flatpickr.formatDate(minAllowedDate, 'Y-m-d')
  if (today !== minAllowedDateWithoutTime) {
    const to = new Date(minAllowedDate)
    to.setDate(minAllowedDate.getDate() - 1)
    const to_date = flatpickr.formatDate(to, 'Y-m-d')
    const exclude_dates = { from: today, to: to_date }
    dates_json.push(exclude_dates)
  }
  return dates_json
}

const allowHalfDays = function (dates_json) {
  for (const booking_dates of dates_json) {
    if (booking_dates.starts_on_moment === 'afternoon') {
      const from_date = new Date(booking_dates.from)
      morning_only_days.push(booking_dates.from)

      from_date.setDate(from_date.getDate() + 1)
      booking_dates.from = flatpickr.formatDate(from_date, 'Y-m-d')
    }
    if (booking_dates.ends_on_moment === 'morning') {
      const to_date = new Date(booking_dates.to)
      afternoon_only_days.push(booking_dates.to)

      to_date.setDate(to_date.getDate() - 1)
      booking_dates.to = flatpickr.formatDate(to_date, 'Y-m-d')
    }
  }
  return dates_json
}

function modal_visibility (modal, calendar) {
  modal.style.display = style_value(modal.style.display)
  calendar.style.display = 'none'
}

function style_value (el_style) {
  let new_value = 'block'
  if (el_style == 'block') {
    new_value = 'none'
  }
  return new_value
}

const calendar_service = function () {
  const mobile_date_recap_selector = document.querySelector('#js-booking-sticky-m-dates-recap')
  const mobile_date_cross = document.querySelector('#js-booking-sticky-cross')
  const open_calendar_selector = document.querySelector('#js-booking-sticky-calendar-button')
  const calendar_container = document.querySelector('#js-booking-sticky-form-container')
  const details_container = document.querySelector('#js-booking-sticky-details-container')

  if (mobile_date_recap_selector && open_calendar_selector) {
    open_calendar_selector.addEventListener('click', function () {
      calendar_container.style.display = style_value(calendar_container.style.display)
    })

    mobile_date_recap_selector.addEventListener('click', function () {
      modal_visibility(details_container, calendar_container)
    })

    mobile_date_cross.addEventListener('click', function () {
      modal_visibility(details_container, calendar_container)
    })
  }
  const car_id = document.querySelector('#booking_car_id').value
  const booking_car_distance_maximum = document.getElementById('booking_car_distance_maximum')
  const price_per_day = Number(document.querySelector('#booking_price_to_display').value)
  let included_distance = 0
  if (booking_car_distance_maximum) {
    included_distance = Number(booking_car_distance_maximum.value)
  }
  const booking = new Booking(car_id, included_distance, price_per_day)

  const addDays = function (date, days) {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }
  const subDays = function (date, days) {
    const result = new Date(date)
    result.setDate(result.getDate() - days)
    return result
  }

  const two_days_min_process = function (selectedDates, datepicker) {
    if (selectedDates.length === 2) {
      const arrayOfDays = getDates(selectedDates[0], selectedDates[1])
      const numberOfDays = arrayOfDays.length + 1
      const MIN_DAYS = 2
      const missing_days = Math.max(0, MIN_DAYS - numberOfDays)
      if (missing_days > 0) {
        if (selectedDates[0].getDay() === 6) {
          const minDate = selectedDates[0]
          const maxDate = addDays(minDate, missing_days)
          datepicker.setDate([minDate, maxDate], true)
          setTimeout(() => {
            datepicker.jumpToDate(selectedDates[0])
            datepicker.open()
          })
        } else if (selectedDates[1].getDay() === 0) {
          const maxDate = selectedDates[1]
          const minDate = subDays(maxDate, missing_days)

          datepicker.setDate([minDate, maxDate], true)
          setTimeout(() => {
            datepicker.jumpToDate(selectedDates[0])
            datepicker.open()
          })
        }
      }
    }
  }

  const initialize_flatpickr = function () {
    let bookedDates = document.querySelector('#booking_booked_dates').value
    if (bookedDates === '') {
      // If no cars booked, we want to send an empty array to :disable in flatpickr
      bookedDates = []
    } else {
      // String to array
      bookedDates = allowHalfDays(JSON.parse(bookedDates))
    }
    bookedDates = restrictMinDate(minAllowedDate, bookedDates)

    // Flatpickr setup
    flatpickr('.datepicker', {
      minDate: 'today',
      disable: bookedDates,
      dateFormat: 'd/m/Y',
      position: 'below',
      locale: 'fr',
      static: helpers.is_mobile(),
      disableMobile: true,
      plugins: [new rangePlugin({ input: '#booking_ends_on' })],
      onChange (selectedDates, value, datepicker) {
        if (document.querySelector('#booking_category').value === 'with_driver' &&
          selectedDates.length >= 1) {
          datepicker.set('maxDate', selectedDates[0]) // addDays() is from date-fns
          datepicker.set('minDate', selectedDates[0])
        }
        if (document.querySelector('#booking_two_days_min').value === 'true') {
          two_days_min_process(selectedDates, datepicker)
        }
      }
    })
  }

  const handleStartDateChange = function () {
    if (booking.startDate < minAllowedDate) {
      let previousElementSelected = false
      for (const element of bookingStartsAtSelector.options) {
        const hours_option = element.value
        const hours_option_split = hours_option.split(':')
        const hours = parseInt(hours_option_split[0])
        const minutes = parseInt(hours_option_split[1])
        if (hours < minAllowedDate.getHours() || hours === minAllowedDate.getHours() && minutes <= minAllowedDate.getMinutes()) {
          if (element.selected) {
            previousElementSelected = true
          }
          element.disabled = 'disabled'
          element.removeAttribute('selected')
        } else if (previousElementSelected) {
          element.selected = 'selected'
          break
        }
      }
    } else {
      for (const element of bookingStartsAtSelector.options) {
        element.removeAttribute('disabled')
      }
    }
  }

  const calculateBookingPrice = function () {

    const formData = new FormData()
    formData.append('numberOfDays', booking.numberOfDays)
    if (booking.deliveryOption) {
      formData.append('deliveryOption', booking.deliveryOption)
    }
    if (!booking.startDate || !booking.endDate) {
      return
    }

    const starts_on = booking.startDate.toJSON()
    const ends_on = booking.endDate.toJSON()

    formData.append('startsOn', starts_on)
    formData.append('endsOn', ends_on)
    const oldCallback = function (response) {
      // @deprecated
      booking.updateTotalPrice(response.newPrice)
      render_new_price()
    }

    const newCallback = function (response) {
      render_detail_price(response)
    }

    const car_id = booking.carId

    const booking_service = new BookingService()
    booking_service.get_detail_booking_price(formData, car_id, newCallback)
  }

  const render_detail_price = function (response) {
    const mobile_price_recap_selector = document.querySelector('#js-booking-sticky-m-price-recap')
    if (mobile_price_recap_selector) {

      document.querySelector('#js-booking-sticky-m-price-recap').innerHTML = `<b>${response.totalPrice}</b> pour ${response.duration} jour${response.duration > 1 ? 's' : ''}`

    } else {
      document.querySelector('#js-total-price').innerText = `${response.totalPrice} `
    }

    // Price text part
    render_price(response)

    const booking_distance_max = document.querySelector('#booking_distance_max')
    if(booking_distance_max){
      booking_distance_max.value = `${response.distance_max} km`
    }
  }



  const render_price = function (response) {

    const service = new RenderPriceService(
      document.querySelector('.js-summary-prices'),
      document.querySelector('.js-recap-prices'),
      document.querySelector('.js-total')
    )

      service.process(response)

    document.querySelector('#js-price-details-link').classList.remove('d-none')


  }

  const render_new_price = function () {
    // @deprecated
    // Price text part

    document.querySelector('.price-total-day').innerHTML = booking.numberOfDays
    document.querySelector('.price-total-day-label').innerHTML = booking.numberOfDays === 1 ? ' jour ' : ' jours '

    if (booking.pricePerDay > booking.newPricePerDay) {
      document.querySelector('#car-detail-header-price').innerHTML =
        '<strike>' + numberWithSeparator(booking.pricePerDay, ' ') + '€</strike> <strong>' +
        numberWithSeparator(booking.newPricePerDay, ' ') + '€</strong> / jour'
    } else {
      document.querySelector('#car-detail-header-price').innerHTML =
        '<strong>' + numberWithSeparator(booking.newPricePerDay, ' ') + '€</strong> / jour'
    }

    document.querySelector('.estimated-price').innerHTML = helpers.prettify_price(booking.totalPrice) + '€'
    document.querySelector('#price-and-kms').classList.remove('d-none')
  }

  const convertFrenchStrDateToDate = function (dateString) {
    const dateParts = dateString.split('/')
    const day = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1
    const year = parseInt(dateParts[2], 10)

    return new Date(year, month, day)
  }

  const formValues = function () {
    const booking_starts_on = document.querySelector('#booking_starts_on').value
    const booking_ends_on = document.querySelector('#booking_ends_on').value
    const booking_delivery_option_selector = document.querySelector('#booking_delivery_option')
    let values = { booking_starts_on, booking_ends_on }
    if (booking_delivery_option_selector) {
      values = { ...values, ...{ booking_delivery_option: booking_delivery_option_selector.value } }
    }
    return values
  }

  const setIndicator = function () {
    // @deprecated
    const values = formValues()

    if (!values.booking_starts_on) {
      document.querySelector('.booking_starts_on_container').classList.add('has-indicator')
    } else if (!values.booking_ends_on) {
      document.querySelector('.booking_starts_on_container').classList.remove('has-indicator')
      document.querySelector('.booking_ends_on_container').classList.add('has-indicator')
    } else if (booking.numberOfDays > 0) {
      document.querySelector('.booking_starts_on_container').classList.remove('has-indicator')
      document.querySelector('.booking_ends_on_container').classList.remove('has-indicator')
      document.querySelector('.km-input').classList.add('has-indicator')

      if (booking.kmInput > 0) {
        document.querySelector('.km-input').classList.remove('has-indicator')
      }
      if ('booking_delivery_option' in values && values.booking_delivery_option) {
        document.querySelector('.booking_delivery_option_container').classList.remove('has-indicator')
      }
    }
  }
  const previousImplementation = function () {
    // @deprecated
    const submitButton = document.getElementById('booking-creation-submit-button')
    const values = formValues()

    if (!values.booking_starts_on || !values.booking_ends_on) {
      return
    }

    const startDate = convertFrenchStrDateToDate(values.booking_starts_on)
    const endDate = convertFrenchStrDateToDate(values.booking_ends_on)

    const startsAt = parseInt(bookingStartsAtSelector.value.replace(/\D+/g, ''))
    const endsAt = parseInt(document.querySelector('#booking_ends_at').value.replace(/\D+/g, ''))

    const startDateChanged = booking.updateDates(startDate, endDate, startsAt, endsAt)

    if (startDateChanged) {
      handleStartDateChange()
    }

    const rawKmInput = document.querySelector('#booking_distance_max').value ?? ''
    const kmInput = Number(rawKmInput.split(' km')[0])

    const booking_delivery_option = document.querySelector('#booking_delivery_option')
    booking.updateKm(kmInput)

    if (booking_delivery_option) {
      booking.updateDeliveryOption(booking_delivery_option.value)
    }

    if (booking.deliveryOption !== '' && booking.deliveryOption != null) {
      document.querySelector('#booking_delivery_option').style.color = '#000000'
    }

    if (booking.numberOfDays > 0) {
      if (booking.kmInput !== 0 && booking.kmInput != null) {
        document.querySelector('.field-distance').style.color = '#000000'
        submitButton.removeAttribute('disabled')
      }
      calculateBookingPrice()
    } else {
      // Hide price & text
      document.querySelector('#price-and-kms').classList.add('d-none')
      // Disable possibility to book if no duration
      submitButton.setAttribute('disabled', true)
    }
  }

  const newImplementation = function () {
    const submitButton = document.getElementById('booking-creation-submit-button')
    const values = formValues()
    const m_dates_recap_selector = document.querySelector('#js-booking-sticky-m-dates-recap')

    if (!values.booking_starts_on || !values.booking_ends_on) {
      if (m_dates_recap_selector) {
        m_dates_recap_selector.innerText = 'Choisir les dates'
      }
      return
    }

    const startDate = convertFrenchStrDateToDate(values.booking_starts_on)
    const endDate = convertFrenchStrDateToDate(values.booking_ends_on)

    const startDateChanged = booking.updateDates(startDate, endDate,
      bookingStartsAtSelector.value, document.querySelector('#booking_ends_at').value)

    if (startDateChanged) {
      handleStartDateChange()
    }

    const rawKmInput = document.querySelector('#booking_distance_max').value ?? ''
    const kmInput = Number(rawKmInput.split(' km')[0])

    const booking_delivery_option = document.querySelector('#booking_delivery_option')
    booking.updateKmInput(kmInput)

    if (booking_delivery_option) {
      booking.updateDeliveryOption(booking_delivery_option.value)
    }

    if (booking.deliveryOption !== '' && booking.deliveryOption != null) {
      document.querySelector('#booking_delivery_option').style.color = '#000000'
    }

    if (booking.numberOfDays > 0) {
      calculateBookingPrice()
      submitButton.removeAttribute('disabled')
    } else {
      // Disable possibility to book if no duration
      submitButton.setAttribute('disabled', true)
    }

    if (m_dates_recap_selector) {
      m_dates_recap_selector.innerText = `${booking.startDate.toLocaleString('default',
        { month: 'short', day: '2-digit' })} → ${booking.endDate.toLocaleString('default',
        { month: 'short', day: '2-digit' })}`

    }
  }

  const updateBookingProperties = function () {
    newImplementation()
  }

  const bookingFormChanged = function () {
    updateBookingProperties()
    //setIndicator()
  }

  const minHoursBeforePaymentSelector = document.getElementById('booking_min_hours_before_payment')
  const minHoursBeforePayment = parseInt(minHoursBeforePaymentSelector?.value) || 0
  const bookingStartsAtSelector = document.querySelector('#booking_starts_at')

  const minAllowedDate = new Date(Date.now() + (minHoursBeforePayment * 60 * 60 * 1000))

  const datepickerIsPresent = document.querySelectorAll('.datepicker').length
  if (datepickerIsPresent) {
    initialize_flatpickr()
  }

  const bookingForm = document.getElementById('new_booking')

  if (bookingForm) {

    const submitButton = document.querySelector('#booking-creation-submit-button')

    const totalPrice = 0

    // By default, User can't book
    submitButton.setAttribute('disabled', true)

    submitButton.addEventListener('click', function (e) {
      e.preventDefault()
      bookingForm.submit()
    })

    bookingForm.addEventListener('change', function () {
      bookingFormChanged()
    })

    bookingFormChanged()

    // Send price to Controller
    bookingForm.addEventListener('submit', function () {
      document.querySelector('#booking_price').value = totalPrice
    })
  }
}

document.addEventListener('turbo:load', function () {
  const page_contains_new_booking_form = document.querySelector('form[id="new_booking"]')
  if (page_contains_new_booking_form) {
    calendar_service()
  }
})

document.addEventListener('ready', function () {
  const page_contains_new_booking_form = document.querySelector('form[id="new_booking"]')
  if (page_contains_new_booking_form) {
    calendar_service()
  }
})
